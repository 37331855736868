<script setup>
import { ref, defineProps, defineEmits } from "vue"
import { Icon } from "@iconify/vue"

defineProps({
  hasFilter: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(["search"])

const keyword = ref("")

const search = () => {
  emits("search", keyword.value)
}
</script>

<template>
  <div class="bg-white border-t border-b border-gray-200">
    <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center">
      <div class="flex-1">
        <slot name="title"></slot>
      </div>
      <div>
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
  <div v-if="hasFilter" class="bg-white border-b w-full px-2 mx-auto h-14 flex flex-row lg:flex-row md:items-center lg:items-center justify-between">
    <div class="container-xl">
      <div class="w-full flex-center">
        <Icon icon="tabler:search" class="w-5 h-5 text-gray-400" />
        <input type="text" :placeholder="$t('search')" class="h-full w-full px-2 focus:outline-none focus:border-none" v-model="keyword" v-debounce:700ms="search" />
      </div>
    </div>
  </div>
</template>
