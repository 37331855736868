<template>
  <div>
    <div v-if="showCongratulation">
      <!-- Show text with a lot of information about the integration -->
      <SubHeader>
        <template #title>
          <div class="flex-center justify-center space-x-3">
            <Title :text="$t('Integration saved successfully, congratulation')" />
          </div>
        </template>
      </SubHeader>
      <div class="container-xl">
        <div class="max-w-2xl mx-auto my-20">
          <div class="flex-center-center space-x-10">
            <div class="text-lg"></div>
            <div class="flex-col-center">
              <img :src="channelFrom.avatar_short" class="w-24 h-24 object-contain" />
              <span class="font-normal text-gray-800">{{ channelFrom.name }}</span>
            </div>
            <div>
              <Icon icon="tabler:link" class="w-10 h-10 text-gray-400" />
            </div>
            <div class="flex-col-center">
              <img :src="channelTo.avatar_short" class="w-24 h-24 object-contain" />
              <span class="font-normal text-gray-800">{{ channelTo.name }}</span>
            </div>
          </div>
          <div v-if="channelFrom?.data_json?.hasWebhooks">
            <p class="text-center text-sm font-light text-gray-600 my-10">{{ $t("Integration external text webhook") }}</p>
            <!-- List services -->
            <div v-for="(link, i) in links" :key="i" class="bg-[#F1F5F9] p-3 flex flex-col justify-between items-center rounded-md mb-2.5 border">
              <div class="w-full flex flex-row justify-between items-center">
                <div class="flex flex-row justify-start items-center font-semibold text-gray-600 relative">
                  <!-- Service icon -->
                  <Icon v-if="link.nameService == 'Customers' && !link.unsubscribe" icon="tabler:users" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.nameService == 'Contacts' && !link.unsubscribe" icon="tabler:users" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.nameService == 'Orders' && !link.unsubscribe" icon="tabler:report-money" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.nameService == 'Newsletter' && !link.unsubscribe" icon="tabler:mail" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.nameService == 'Products' && !link.unsubscribe" icon="tabler:list-details" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.nameService == 'Carts' && !link.unsubscribe" icon="tabler:shopping-cart" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.nameService == 'Tickets' && !link.unsubscribe" icon="tabler:ticket" class="w-6 h-6 ml-2 text-gray-400" />
                  <Icon v-if="link.unsubscribe" icon="tabler:user-minus" class="w-6 h-6 ml-2 text-gray-400" />

                  <!-- Service name -->
                  <p v-if="!link.unsubscribe" class="text-gray-600 text-sm font-semibold ml-3">{{ link.nameService }}</p>
                  <p v-else class="text-gray-600 text-sm font-semibold ml-3">Unsubscribe</p>
                </div>
                <div class="flex-center space-x-3">
                  <Button @click="copyWebhookLink(link, link.url)" type="button" class="p-button-sm p-button-secondary">
                    <Icon icon="tabler:copy" class="w-5 h-5 mr-1" />
                    <span>{{ link.copied ? $t("copied!") : $t("copyLink") }}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-center text-sm font-light text-gray-600 my-10">{{ $t("Integration external text") }}</p>
          </div>
          <div class="flex-center-center space-x-3 my-5">
            <a :href="channelTo?.link" target="_blank" class="inline-block">
              <Button type="button" :label="$t('Go to X', { name: channelTo.name })" icon="pi pi-external-link" iconPos="right" class="p-button-success shadow" />
            </a>

            <router-link :to="{ name: 'Integrations', params: { id: currentProjectId } }">
              <Button :label="$t('Go to my integrations')" type="button" icon="pi pi-arrow-right" iconPos="right" class="p-button-light shadow" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Form integration -->
    <div v-else>
      <SubHeader>
        <template #title>
          <div class="flex-center space-x-3" :class="[isEditing ? 'justify-start' : 'justify-center']">
            <Title :text="isEditing ? $t('integrations.formIntegration.wrapperTitleEdit') : $t('integrations.formIntegration.wrapperTitleNew')" />
            <span v-if="!isEditing">🚀</span>
          </div>
        </template>
      </SubHeader>
      <div class="container-xl">
        <div class="w-full sm:max-w-2xl mx-auto mb-20 mt-3">
          <!-- Loading edit integration -->
          <div v-if="gettingDataToEdit" class="w-full">
            <div class="space-y-3">
              <Message severity="info" class="ring-1 ring-blue-200" :closable="false">
                {{ $t("We are loading your integration data, validating access and permissions.") }}
                <br />
                {{ $t("This action may take a moment...") }}
              </Message>
              <Card v-for="count in 3" :key="count">
                <template #content>
                  <div class="flex flex-col w-full -my-3">
                    <Skeleton width="100%" height="11rem" />
                  </div>
                </template>
              </Card>
            </div>
          </div>

          <!-- Show form after edit integration was loaded -->
          <div v-show="!gettingDataToEdit" class="w-full">
            <!-- Form channel from -->
            <div v-if="showFirstStep">
              <Card class="border border-gray-400">
                <template #header>
                  <div class="bg-[#1F3121] px-4 py-3 rounded-t-md border-b border-gray-300">
                    <div class="flex-center space-x-4">
                      <div class="flex-center bg-white rounded-md shadow p-2">
                        <Icon icon="tabler:database-export" class="text-gray-800 w-10 h-10" />
                      </div>
                      <div class="flex-1">
                        <span class="text-white block text-xl font-semibold">{{ $t("integrations.formIntegration.titleFromSelect") }}</span>
                        <span class="text-white block text-xs font-semibold">{{ $t("integrations.formIntegration.titleFromText") }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #content>
                  <div class="flex flex-col w-full">
                    <ConnectChannel ref="connectChannelFrom" @data="saveChannelFrom" @mounted="onConnectChannelFromMounted" :editing="isEditing" :selectData="selectDataFrom" :hasVerify="true" verifyType="from" />
                  </div>
                </template>
              </Card>
            </div>

            <!-- Form channel to -->
            <div v-if="showSecondStep">
              <div class="w-[1px] h-12 mx-auto border-r-2 border-dashed border-gray-400"></div>
              <Card class="border border-gray-400">
                <template #header>
                  <div class="bg-[#1F3121] px-4 py-3 rounded-t-md border-b border-gray-300">
                    <div class="flex-center space-x-4">
                      <div class="flex-center bg-white rounded-md shadow p-2">
                        <Icon icon="tabler:database-import" class="text-gray-800 w-10 h-10" />
                      </div>
                      <div class="flex-1">
                        <span class="text-white block text-xl font-semibold">{{ $t("integrations.formIntegration.titleToSelect") }}</span>
                        <span class="text-white block text-xs font-semibold">{{ $t("integrations.formIntegration.titleToText") }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #content>
                  <div class="flex flex-col w-full">
                    <ConnectChannel ref="connectChannelTo" @data="saveChannelTo" @mounted="onConnectChannelToMounted" :editing="isEditing" :selectData="selectDataTo" :hasVerify="true" verifyType="to" />
                  </div>
                </template>
              </Card>
            </div>

            <!-- Form services -->
            <div v-if="showThirdStep">
              <div class="w-[1px] h-12 mx-auto border-r-2 border-dashed border-gray-400"></div>
              <Card class="border border-gray-400">
                <template #header>
                  <div class="bg-[#1F3121] px-4 py-3 rounded-t-md border-b border-gray-300">
                    <div class="flex-center space-x-4">
                      <div class="flex-center bg-white rounded-md shadow p-2">
                        <Icon icon="tabler:list-check" class="text-gray-800 w-10 h-10" />
                      </div>
                      <div class="flex-1">
                        <span class="text-white block text-xl font-semibold">{{ $t("integrations.formIntegration.servicesTitleConfig") }}</span>
                        <span class="text-white block text-xs font-semibold">{{ $t("integrations.formIntegration.servicesTitleConfigText") }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #content>
                  <div v-if="savingConfig">
                    <AutoMapperSkeleton />
                  </div>
                  <div v-else>
                    <!-- SetUp missing premium services -->
                    <div v-if="channelTo.id == 7 && !channelFrom.services.filter((service) => service.config?.canPremium === true || service.config?.isPremiumForEver == true).length">
                      <Message severity="warn" :closable="false">{{ $t("There are no premium services for the destination channel") }}</Message>
                    </div>
                    <!-- AutoMapper -->
                    <div v-else class="flex flex-col w-full">
                      <AutoMapper ref="automapper" :projectCredentialFromId="projectCredentialFrom.id" :projectCredentialToId="projectCredentialTo.id" :channelFrom="channelFrom" :servicesFrom="channelFrom.services" :channelTo="channelTo" :serviceTo="channelTo.services_output[0]" :integrations="integrations" @deleteIntegration="onDeleteIntegration" @dataFinish="finishIntegration" />
                    </div>
                  </div>
                </template>
              </Card>

              <!-- Form submit button -->
              <div class="flex-center justify-end mt-5">
                <Button type="button" @click="createIntegration" :loading="savingIntegration" :label="isEditing ? $t('integrations.formIntegration.buttonSave') : $t('integrations.formIntegration.Create integration')" icon="pi pi-arrow-right" iconPos="right" class="p-button-primary shadow-md" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Framework components
import { Icon } from "@iconify/vue"
import Card from "primevue/card"
import Button from "primevue/button"
import Message from "primevue/message"
import Skeleton from "primevue/skeleton"
// App components
import SubHeader from "@/components/App/SubHeader"
import Title from "@/components/App/Title"
import AutoMapper from "@/components/WizardNew/AutoMapper"
import AutoMapperSkeleton from "@/components/Integrations/Wizard/AutoMapperSkeleton"
import ConnectChannel from "@/components/WizardNew/ConnectChannel"
import axiosClient from "@/config/AxiosClient"

export default {
  name: `Form Integration`,

  components: {
    // Framework components
    Icon,
    Card,
    Button,
    Message,
    Skeleton,
    // App components
    SubHeader,
    Title,
    AutoMapper,
    AutoMapperSkeleton,
    ConnectChannel
  },

  data() {
    return {
      currentProjectId: this.$route.params?.id || null,
      currentProjectConnectionId: this.$route.params?.projectConnectionId || null,
      projectConnection: {},
      channelFrom: {},
      channelTo: {},
      projectCredentialFrom: {},
      projectCredentialTo: {},
      selectDataFrom: {},
      selectDataTo: {},
      integrations: [],
      deleteIntegration: [],
      links: [],
      gettingDataToEdit: false,
      savingIntegration: false,
      savingConfig: false,
      showCongratulation: false
    }
  },

  computed: {
    showFirstStep() {
      return !this.isEditing || Object.keys(this.projectConnection).length
    },
    showSecondStep() {
      return this.projectCredentialFrom?.id || false
    },
    showThirdStep() {
      return this.projectCredentialFrom?.id && this.projectCredentialTo?.id && this.channelFrom?.services.length && this.channelTo?.services_output.length
    },
    isEditing() {
      return this.currentProjectConnectionId ? true : false
    }
  },

  mounted() {
    if (this.currentProjectConnectionId) {
      this.getIntegration()
    }
  },

  methods: {
    async getIntegration() {
      this.gettingDataToEdit = true

      const response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/connection/${this.currentProjectConnectionId}`)

      this.projectConnection = response.data.data
      this.selectDataFrom = { channel_id: response.data.data.channel_id, project_credential_id: response.data.data.project_credential_from_id }
      this.selectDataTo = { channel_id: response.data.data.channel_output_id, project_credential_id: response.data.data.project_credential_to_id }
      this.integrations = response.data.data.integrations
    },

    async saveChannelFrom(data) {
      this.channelFrom = data.channel
      this.projectCredentialFrom = data.projectCredential

      if (data.channel && data.projectCredential) {
        this.selectDataFrom = { channel_id: data.channel.id, project_credential_id: data.projectCredential.id }
      }

      if (this.projectCredentialFrom?.id && this.projectCredentialTo?.id) {
        this.projectConnection = await this.saveConfig()
      }
    },

    async saveChannelTo(data) {
      this.channelTo = data.channel
      this.projectCredentialTo = data.projectCredential

      if (data.channel && data.projectCredential) {
        this.selectDataTo = { channel_id: data.channel.id, project_credential_id: data.projectCredential.id }
      }

      if (this.projectCredentialFrom?.id && this.projectCredentialTo?.id) {
        this.projectConnection = await this.saveConfig()
      }

      this.gettingDataToEdit = false
    },

    async onDeleteIntegration(service) {
      if (service.integration_id) this.deleteIntegration.push(service.integration_id)
    },

    async saveConfig() {
      this.savingConfig = true

      let response

      if (Object.keys(this.projectConnection).length) {
        response = await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/integrations/${this.projectConnection.id}/config`, {
          project_credential_from_id: this.projectCredentialFrom.id,
          project_credential_to_id: this.projectCredentialTo.id
        })
      } else {
        response = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/integrations`, {
          project_id: this.$store.state.Projects.activeProject.id,
          project_credential_from_id: this.projectCredentialFrom.id,
          project_credential_to_id: this.projectCredentialTo.id
        })
      }

      this.savingConfig = false

      return response.data.data
    },

    async createIntegration() {
      this.$refs.automapper.sendDataFinish()
    },

    async finishIntegration(data) {
      if (data.error) {
        return
      }

      this.savingIntegration = true

      let response = await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/integrations/${this.projectConnection.id}/automapper`, { data, deleteIntegration: this.deleteIntegration })
      if (response.data.links) {
        this.links = response.data.links
      }

      this.savingIntegration = false

      if (this.$route.params.projectConnectionId || this.channelTo.id == 7) {
        this.$toast.success(this.$t("Integration saved successfully"))
        this.$router.push({ name: "Integrations", params: { id: this.currentProjectId } })
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" })
        this.showCongratulation = true
      }
    },

    onConnectChannelFromMounted() {
      this.channelFrom = {}
      this.projectCredentialFrom = {}
    },

    onConnectChannelToMounted() {
      this.channelTo = {}
      this.projectCredentialTo = {}
    },

    copyWebhookLink(service, string) {
      service.copied = true
      this.$copyText(string)
      setTimeout(() => {
        service.copied = false
      }, 2000)
    }
  }
}
</script>
