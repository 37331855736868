<template>
  <div class="w-full flex flex-col flex-center-center">
    <Card class="w-full flex flex-col rounded-lg">
      <template #header>
        <div class="bg-[#1F3121] px-4 py-3 rounded-t-md">
          <div class="flex-center space-x-4">
            <div class="flex-center bg-white rounded-md shadow p-2">
              <img :src="channelModifiable.avatar_short" :alt="`${channelModifiable.avatar_short} logo`" class="w-10 h-10 object-contain" />
            </div>
            <div class="flex-1">
              <p class="font-semibold text-white">{{ channelModifiable.name }}</p>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div class="flex flex-col w-full space-y-2">
          <div class="flex flex-col w-full">
            <label for="connection-name" class="form-label-inline">1 - {{ $t("connections.formConnection.name") }} <LabelRequired /></label>
            <label class="form-label-help">{{ $t("connections.formConnection.name detail") }}</label>
            <InputText v-model="nameConnection" id="connection-name" type="text" class="p-inputtext-sm" autofocus />
            <span v-if="nameConnectionHasError" class="mt-1 text-sm text-red-600">{{ $t("This field is required.") }}</span>
            <div class="mt-2 form-group">
              <Message severity="info" :closable="false" icon="pi pi-info-circle">
                <a :href="linkToDocs" target="_blank" class="underline">{{ $t("wizard.stepByStepForCreatignTheTokens") }}</a>
              </Message>
            </div>
          </div>
          <div class="flex flex-col w-full">
            <TypeForm v-if="channelModifiable.type_field == 'form'" :channel="channelModifiable" @saveCredential="saveCredential" ref="credentialComponent" />
            <TypeOAuth2 v-else :channel="channelModifiable" :projectCredential="projectCredential" :hiddenInputWithValue="hiddenInputWithValue" @saveCredential="saveCredential" ref="credentialComponent" />
          </div>
          <div v-if="errors.length && !loading">
            <div v-for="(error, i) in errors" :key="i" class="alert alert-error px-2 mt-1 text-sm font-semibold">
              <div class="flex-1 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                </svg>
                <label>{{ error }}</label>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex-center justify-end space-x-3">
          <Button v-if="showClose" type="button" @click="$emit('close')" :label="$t('integrations.automapper.cancelButton')" class="p-button-secondary p-button-text" />
          <Button @click="sendCredential" type="button" :label="textButtonSave || $t('connections.formConnection.save')" icon="pi pi-arrow-right" iconPos="right" :loading="this.loading" class="p-button-primary shadow" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
// Framework components
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import TypeForm from "@/components/ChannelConnections/TypeForm.vue"
import TypeOAuth2 from "@/components/ChannelConnections/TypeOAuth2.vue"
import Message from "primevue/message"
import axiosClient from "@/config/AxiosClient"
import Card from "primevue/card"

// App components
import LabelRequired from "@/components/LabelRequired"

export default {
  name: "FormConnection",

  components: {
    Button,
    InputText,
    TypeForm,
    TypeOAuth2,
    LabelRequired,
    Message,
    Card
  },

  props: {
    projectCredential: {
      type: Object,
      default: function () {
        return {}
      }
    },
    project: {
      type: Object
    },
    channel: {
      type: Object
    },
    hiddenInputWithValue: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    textButtonSave: {
      type: String,
      default: ""
    },
    borderDiv: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      nameConnection: "",
      nameConnectionHasError: false,
      credentials: {},
      credentialsConnectionHasError: false,
      channelModifiable: JSON.parse(JSON.stringify(this.channel)),
      documentationTokens: {
        Vtex: "https://help.setup.baymark.net/es/docs/vtex-crear-credenciales-de-acceso",
        Magento1: "https://help.setup.baymark.net/es/docs/magento-1-crear-credenciales-de-acceso",
        Magento2: "https://help.setup.baymark.net/es/docs/magento-2-crear-credenciales-de-acceso",
        Prestashop: "https://help.setup.baymark.net/es/docs/prestashop-crear-credenciales-de-acceso",
        "Tienda Nube": "https://help.setup.baymark.net/es/docs/tiendanube-crear-credenciales-de-acceso",
        WooCommerce: "https://help.setup.baymark.net/es/docs/woocommerce-crear-credenciales-de-acceso",
        Shopify: "https://help.setup.baymark.net/es/docs/shopify-crear-credenciales-de-acceso",
        Simbel: "https://help.setup.baymark.net/es/docs/simbel-crear-credenciales-de-acceso",
        Fenicio: "https://help.setup.baymark.net/es/docs/fenicio-crear-credenciales-de-acceso",
        "Salesforce Commerce": "https://help.setup.baymark.net/es/docs/salesforce-commerce-crear-credenciales-de-acceso",
        MercadoShops: "https://help.setup.baymark.net/es/docs/mercadoshops-crear-credenciales-de-acceso",
        Hubspot: "https://help.setup.baymark.net/es/docs/hubspot-crear-credenciales-de-acceso",
        "Salesforce CRM": "https://help.setup.baymark.net/es/docs/salesforce-crm-crear-credenciales-de-acceso",
        ZohoCRM: "https://help.setup.baymark.net/es/docs/zoho-crm-crear-credenciales-de-acceso",
        WebHook: "https://help.setup.baymark.net/es/docs/webhook-crear-credenciales-de-acceso",
        MailUp: "https://help.setup.baymark.net/es/docs/mailup-crear-credenciales-de-acceso",
        Acumbamail: "https://help.setup.baymark.net/es/docs/acumbamail-crear-credenciales-de-acceso",
        SetUp: "https://help.setup.baymark.net/es/docs/setup-crear-credenciales-de-acceso",
        "Social WiFi": "https://help.setup.baymark.net/es/docs/social-wifi-crear-credenciales-de-acceso",
        Nidux: "https://help.setup.baymark.net/es/docs/nidux-crear-credenciales-de-acceso",
        BigCommerce: "https://help.setup.baymark.net/es/docs/bigcommerce-crear-credenciales-de-acceso",
        Luna: "https://help.setup.baymark.net/es/docs/luna-crear-credenciales-de-acceso"
      },
      documentationInstructions: {
        Vtex: "https://help.setup.baymark.net/es/docs/conectando-vtex",
        Magento1: "https://help.setup.baymark.net/es/docs/conectando-magento-1",
        Magento2: "https://help.setup.baymark.net/es/docs/conectando-magento-2",
        Prestashop: "https://help.setup.baymark.net/es/docs/conectando-prestashop",
        "Tienda Nube": "https://help.setup.baymark.net/es/docs/conectando-tienda-nube",
        WooCommerce: "https://help.setup.baymark.net/es/docs/conectando-woocommerce",
        Shopify: "https://help.setup.baymark.net/es/docs/conectando-shopify",
        Simbel: "https://help.setup.baymark.net/es/docs/conectando-simbel",
        Fenicio: "https://help.setup.baymark.net/es/docs/conectando-fenicio",
        "Salesforce Commerce": "https://help.setup.baymark.net/es/docs/conectando-salesforce-commerce",
        MercadoShops: "https://help.setup.baymark.net/es/docs/conectando-mercadoshops",
        Hubspot: "https://help.setup.baymark.net/es/docs/conectando-hubspot",
        "Salesforce CRM": "https://help.setup.baymark.net/es/docs/conectando-salesforce",
        ZohoCRM: "https://help.setup.baymark.net/es/docs/conectando-zoho",
        WebHook: "https://help.setup.baymark.net/es/docs/conectando-webhook",
        MailUp: "",
        Acumbamail: "",
        SetUp: "",
        "Social WiFi": "https://help.setup.baymark.net/es/docs/conectando-social-wifi",
        Nidux: "https://help.setup.baymark.net/es/docs/conectando-nidux",
        BigCommerce: "https://help.setup.baymark.net/es/docs/conectando-bigcommerce",
        Luna: "https://help.setup.baymark.net/es/docs/conectando-luna"
      },
      errors: []
    }
  },

  computed: {
    linkToDocs() {
      return this.documentationTokens[this.channelModifiable.name] || "https://help.setup.baymark.net/es/docs/conectando-canales"
    }
  },

  created() {
    if (Object.keys(this.projectCredential).length) {
      this.nameConnection = this.projectCredential.name
      this.credentials = this.projectCredential.config || {}
      if (this.channelModifiable.fields != null && this.channelModifiable.fields.length) {
        let inputs = JSON.parse(JSON.stringify(this.channelModifiable.fields.filter(f => !f.notShowV1)))
        this.channelModifiable.fields = inputs.map((input) => {
          if (this.projectCredential.config && this.projectCredential.config[input.name]) {
            input.value = this.projectCredential.config[input.name]
          } else {
            input.value = ""
          }

          if (this.hiddenInputWithValue && input.value != null && input.value != "") {
            input.type = "hidden"
          }
          return input
        })
      } else {
        this.channelModifiable.fields = []
      }
    }
  },

  methods: {
    async sendCredential() {
      await this.$refs.credentialComponent.sendCredential()

      // Start custom validation
      if (!this.nameConnection) {
        this.nameConnectionHasError = true
        return
      } else {
        this.nameConnectionHasError = false
      }

      if (this.credentialsConnectionHasError) {
        return
      }
      // End custom validation

      let response = {}

      this.loading = true

      if (!Object.keys(this.projectCredential).length) {
        try {
          response = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.project.id}/channel/${this.channelModifiable.id}/credentials`, {
            name: this.nameConnection,
            config: this.credentials
          })

          this.$emit("credentialCreate", { ...response.data })
          this.$toast.success(this.$t("connections.formConnection.alertSuccess"))
        } catch (error) {
          Object.values(error.response.data.errors).forEach((value) => {
            value.forEach(item => {
              this.errors.push(item.replace('config.', ''))
            })
          })
          this.$toast.error(this.$t("connections.formConnection.alertError"))
        }
      } else {
        try {
          response = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/credential/${this.projectCredential.id}`, {
            name: this.nameConnection,
            config: this.credentials
          })

          let data = JSON.parse(JSON.stringify(this.projectCredential))
          data.name = this.nameConnection
          data.config = this.credentials
          this.$emit("credentialCreate", data)
          this.$toast.success(this.$t("connections.formConnection.alertSuccess"))
        } catch (error) {
          this.$toast.error(this.$t("connections.formConnection.alertError"))
        }
      }

      this.loading = false
    },

    saveCredential(data, credentialsConnectionHasError) {
      this.credentials = data
      this.credentialsConnectionHasError = credentialsConnectionHasError
    }
  }
}
</script>
