<template>
  <BlockUI :blocked="loadingServices" :baseZIndex="100" :autoZIndex="false">
    <BlockUILoader v-if="loadingServices" :text="$t('wizard.validating')" />

    <!-- Title connect channel -->
    <div v-if="title" class="text-lg font-semibold mb-3">{{ title }}</div>

    <!-- Select the channel -->
    <div class="form-group">
      <label class="form-label-inline">{{ $t("Select channel") }} <LabelRequired /></label>
      <span v-if="verifyType == 'from'" class="form-label-help">{{ $t("Select from channel details") }}</span>
      <span v-if="verifyType == 'to'" class="form-label-help">{{ $t("Select to channel details") }}</span>

      <div v-if="loadingChannel">
        <Skeleton width="100%" height="2.5rem" />
      </div>
      <div v-else>
        <Dropdown v-model="channelSelected" @change="searchConnections" :options="channelOptions" :disabled="editing" optionLabel="name" dataKey="id" :filter="true" :autoFilterFocus="true" placeholder="--SELECCIONE--" scrollHeight="20vw" class="shadow-sm w-full">
          <template #value="slotProps">
            <div class="p-dropdown-item-value flex-center" v-if="slotProps.value">
              <img :alt="slotProps.value.name" :src="slotProps.value.avatar_short" class="w-7 h-7 object-contain mr-3" />
              <span class="font-semibold">{{ slotProps.value.name }}</span>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="p-dropdown-item-option flex-center">
              <img :alt="slotProps.option.name" :src="slotProps.option.avatar_short" class="w-10 h-10 object-contain mr-3" />
              <span class="font-semibold">{{ slotProps.option.name }}</span>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>

    <!-- Select the connection -->
    <div v-if="channelSelected">
      <div class="form-group">
        <label class="form-label-inline">{{ $t("Select credential") }} <LabelRequired /></label>
        <span class="form-label-help">{{ $t("Select credential details") }}</span>

        <div v-if="loadingConnections">
          <Skeleton width="100%" height="2.5rem" />
        </div>
        <div v-else>
          <!-- If has connections then show dropdown and buttons -->
          <div v-if="connections.length" class="flex-center space-x-3">
            <div class="flex-1">
              <Dropdown v-model="connectionSelected" @change="selectConnection()" :options="connections" ref="connections" optionLabel="name" dataKey="id" :showClear="true" placeholder="--SELECCIONE--" scrollHeight="20vw" class="p-inputtext-sm shadow-sm w-full" />
            </div>
            <div v-if="$verifyPermission(permissions, ['v2.client.project.create.credentials', 'v2.client.project.edit.credential'])">
              <Button v-if="connectionSelected" @click="toggleShowFormConnection" type="button" class="p-button-secondary" v-tooltip.top="$t('connections.connectChannel.editConnection')">
                <Icon icon="tabler:edit" class="w-5 h-5" />
              </Button>
              <Button v-else @click="toggleShowFormConnection" type="button" class="p-button-secondary" v-tooltip.top="$t('connections.connectChannel.createConnection')">
                <Icon icon="tabler:playlist-add" class="w-5 h-5" />
              </Button>
            </div>
          </div>
          <!-- If not then show a button -->
          <div v-else-if="$verifyPermission(permissions, ['v2.client.project.create.credentials', 'v2.client.project.edit.credential'])">
            <Button @click="toggleShowFormConnection" type="button" class="p-button-secondary w-full flex-center-center">
              <Icon icon="tabler:playlist-add" class="w-5 h-5" />
              <span class="ml-2 font-semibold">{{ $t("connections.connectChannel.createConnection") }}</span>
            </Button>
          </div>
        </div>
        <router-link :to="{ name: 'Channels', params: { id: $route.params.id } }" target="_blank">
          <div class="form-label-help mt-2">
            <div class="flex-center space-x-1 underline">
              <Icon icon="tabler:external-link" class="w-3 h-3" />
              <span>{{ $t("You can manage all of your credentials here.") }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </BlockUI>

  <Dialog v-model:visible="showFormConnection" modal :style="{ width: '36rem', padding: 0 }" contentClass="without-padding bg-transparent" position="center" :showHeader="false" :dismissableMask="true">
    <FormConnection :project="$store.state.Projects.activeProject" :channel="channelSelected" :projectCredential="connectionSelected || {}" @credentialCreate="saveCredential" :showClose="true" @close="toggleShowFormConnection" />
  </Dialog>
</template>

<script>
import { Icon } from "@iconify/vue"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"
import Skeleton from "primevue/skeleton"
import BlockUI from "primevue/blockui"
import axiosClient from "@/config/AxiosClient"
import FormConnection from "@/components/ChannelConnections/FormConnection"
import LabelRequired from "@/components/LabelRequired"
import BlockUILoader from "@/components/UI/BlockUILoader"
import { mapGetters } from 'vuex'

export default {
  name: "ConnectChannel",

  components: {
    Dialog,
    FormConnection,
    Icon,
    Button,
    Dropdown,
    Skeleton,
    BlockUI,
    LabelRequired,
    BlockUILoader
  },

  emits: ["data", "mounted"],

  props: {
    title: String,
    verifyType: String,
    hasVerify: Boolean,
    selectData: Object,
    editing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      channelSelected: null,
      connectionSelected: null,
      connections: [],
      services: [],
      showFormConnection: false,
      loadingChannel: false,
      loadingConnections: false,
      loadingServices: false
    }
  },

  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
    channelOptions() {
      // TODO: crear filtro
      if (this.verifyType == "from") {
        return this.$store.state.Channels.channels.filter((f) => f.services.length > 0)
      } else if (this.verifyType == "to") {
        return this.$store.state.Channels.channels.filter((f) => f.services_output.length > 0)
      } else {
        return this.$store.state.Channels.channels.filter((f) => f.services_segment.length > 0)
      }
    }
  },

  async mounted() {
    this.$emit("mounted")

    // Get channels
    await this.getChannelOptions()

    // If already has a channel then select (normally this is use for editing or quick selection)
    if (Object.keys(this.selectData).length) {
      const channelSelectFound = this.channelOptions.find((channelOption) => this.selectData.channel_id == channelOption.id)
      if (channelSelectFound) {
        this.channelSelected = channelSelectFound
        await this.searchConnections()
      }
    }
  },

  methods: {
    async getChannelOptions() {
      // Get all channel to use the component
      if (!this.channelOptions.length) {
        this.loadingChannel = true
        await this.$store.dispatch("Channels/getChannels")
        this.loadingChannel = false
      }
    },

    async searchConnections() {
      // Reset state of connections
      this.connectionSelected = null
      this.connections = []

      this.loadingConnections = true

      // Get connections for the channel selected
      let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.$route.params.id}/credentials?filter[channel_id][%3D]=${this.channelSelected.id}&sort=-id`)

      this.loadingConnections = false

      // Save options
      this.connections = response.data.data

      let connectionSelectFound = null

      // If already has a connection then we select it (normally this is use for editing or quick selection)
      if (Object.keys(this.selectData).length) {
        connectionSelectFound = response.data.data.find((connection) => connection.id == this.selectData.project_credential_id)
        if (connectionSelectFound) {
          this.selectConnection(connectionSelectFound)
        }
      }

      // If the connectionSelected is null and the channel is SetUp or Webhooks normally has one connection, then we selected automatically
      if (!connectionSelectFound && [7].includes(this.channelSelected.id)) {
        connectionSelectFound = response.data.data[0]
        this.selectConnection(connectionSelectFound)
      }

      if (!connectionSelectFound) {
        this.sendData()
      }
    },

    async selectConnection(connection) {
      if (connection) {
        this.connectionSelected = connection
      }

      if (this.connectionSelected?.id) {
        // Save the correct services depending for the verifyTpe
        if (this.verifyType == "from") {
          let services = JSON.parse(JSON.stringify(this.channelSelected.services))
          this.services = services.map((service) => {
            service.loading = true
            service.err = {}
            service.fields = []
            return service
          })
        } else if (this.verifyType == "to") {
          let services = JSON.parse(JSON.stringify(this.channelSelected.services_output))
          this.services = services.map((service) => {
            service.loading = true
            service.err = {}
            service.fields = []
            return service
          })
        } else {
          let services = JSON.parse(JSON.stringify(this.channelSelected.services_segment))
          this.services = services.map((service) => {
            service.loading = true
            service.err = {}
            service.fields = []
            return service
          })
        }

        // Here we can send the data to the parent component or we can validate and after that send the data.
        if (!this.hasVerify) {
          this.sendData()
          return
        }

        if (this.verifyType == "segment") {
          this.sendData()
          return
        }

        this.validateServices()
      } else {
        // Call to the parent component
        this.$emit("data", { channel: this.channelSelected, projectCredential: this.connectionSelected })
      }
    },

    async toggleShowFormConnection() {
      this.showFormConnection = !this.showFormConnection
    },

    async saveCredential(data) {
      // Add new credential or update it
      const index = this.connections.findIndex((connection) => connection.id == data.id)
      if (index == -1) {
        this.connections.push(data)
      } else {
        this.connections[index] = data
      }

      // Close the dialog and show UI elements to continue the wizard.
      this.toggleShowFormConnection()

      this.selectConnection(data)
    },

    validateServices() {
      this.loadingServices = true
      let services = this.services.filter(f => f.config['isActive'] == true)
      let promiseArray = services.map(service => axiosClient
        .post(`${process.env.VUE_APP_URL_API}/api/2.0/integrations/check-services/${this.verifyType}`, {
          service_id: service.id,
          project_credential_id: this.connectionSelected.id
      }))
      Promise.all(promiseArray.map((promise, index) => {
        return promise.then(response => {
          let indexService = this.services.findIndex(f => f.id == services[index].id)
          if(!response.data.errors){
            this.services[indexService].fields = response.data
            this.services[indexService].loading = false
          }else{
            this.services[indexService].err = response.data.errors
            this.services[indexService].loading = false
          }
        })
      })).finally(() => {
        this.loadingServices = false
        this.sendData()
      })

      /* Promise.all(promiseArray).then(responses => {
        console.log('ff')
        responses.forEach((response, index) => {
          console.log(index)
          let indexService = this.services.findIndex(f => f.id == services[index].id)
          this.services[indexService].fields = response.data
          this.services[indexService].loading = false
        })
        this.loadingServices = false
        this.sendData()
      }).catch(error => {
        console.log(error)
      }) */

      /* for (const [index, service] of this.services.entries()) {
        if(service.config['isActive'] || this.verifyType == "to"){
          axiosClient
            .post(`${process.env.VUE_APP_URL_API}/api/2.0/integrations/check-services/${this.verifyType}`, {
              service_id: service.id,
              project_credential_id: this.connectionSelected.id
            })
            .then((response) => {
              this.services[index].fields = response.data
              this.services[index].loading = false
              if (index == this.services.length - 1) {
                this.loadingServices = false
                this.sendData()
              }
            })
            .catch((error) => {
              this.services[index].err = error
              this.services[index].loading = false
              if (index == this.services.length - 1) {
                this.loadingServices = false
                this.sendData()
              }
          })
        }
      } */
    },

    sendData() {
      // Copy a fresh version of the servicio json object
      let services = JSON.parse(JSON.stringify(this.services))
      let channelSelected = JSON.parse(JSON.stringify(this.channelSelected))

      // Clean the object copied
      services.forEach((service) => {
        delete service.loading
        if(!Object.keys(service.err).length){
          delete service.err
        }
      })

      // Save in the correct services type
      if (this.verifyType == "from") {
        channelSelected.services = services
      } else if (this.verifyType == "to") {
        channelSelected.services_output = services
      } else {
        channelSelected.services_segment = services
      }

      // Call to the parent component
      this.$emit("data", { channel: channelSelected, projectCredential: this.connectionSelected })
    }
  }
}
</script>
